/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react"
import { useMediaQuery } from "react-responsive"

const MenuItems = props => {
  const { children } = props

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  const menuItemsStyles = isMobile
    ? css``
    : css`
        &:after,
        &:before {
          bottom: 100%;
          left: calc(410px - 25px);
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: #fff;
          border-width: 8px;
          margin-left: -8px;
        }
        &:before {
          border-color: rgba(235, 235, 235, 0);
          border-bottom-color: #ebebeb;
          border-width: 9px;
          margin-left: -9px;
        }
      `

  return (
    <div
      className={`
        origin-bottom bottom-0 fixed left-0 right-0 mt-4 w-full rounded-[15px_15px_0_0]   shadow-[0px_6px_20px_rgba(34,34,34,0.05)] bg-white z-[9] ring-1 ring-[#EBEBEB] focus:outline-none
        lg:origin-top-right lg:bottom-auto lg:absolute lg:left-auto lg:w-[410px] lg:rounded-[8px]`}
      css={menuItemsStyles}
    >
      {children}
    </div>
  )
}

export default MenuItems
